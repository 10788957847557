import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ReviewWithVideoSection } from 'components/ReviewWithVideoSection';

export const SATAndGmatGreStudentExperience = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "SAT_Landing" }) {
          content
        }
      }
    }
  `);
  return (
    <ReviewWithVideoSection
      data={data.gcms.jsonContanier.content.satReviews}
      footerTitle="HỌC VIÊN"
      videoPosition="left"
    />
  );
};
